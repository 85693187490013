import type {
  ComponentProps,
  JSXElementConstructor,
  PropsWithChildren,
  ReactNode,
} from 'react'
import {ClassNameValue} from 'tailwind-merge'

export type HeadlessProps<
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<unknown>,
  C extends Record<string, unknown> | string
> = ComponentProps<T> &
  StyledHeadlessProps<C> & {
    components?: Partial<
      Record<
        C extends string ? C : keyof C,
        (CProps: PropsWithChildren<StyledHeadlessProps<C>>) => ReactNode
      >
    >
  }

export type StyledHeadlessProps<C extends Record<string, unknown> | string> = {
  classNames?: Partial<Record<C extends string ? C : keyof C, ClassNameValue>>
  unstyled?: boolean
}

export const raise = <Err extends Error>(err: Err | string): never => {
  throw typeof err === `string` ? new Error(err) : err
}
