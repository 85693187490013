import {CopyDetail} from '@myadbox/gatsby-theme-nebula'
import {Asset, useAccount, useProfiles} from '@myadbox/nebula-service-api'
import {AssetType} from '@myadbox/nebula-template-utils'
import {Text, TextArea} from '@myadbox/stellar-ui'
import {useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import withTranslationProvider from '../../../locales/withTranslationProvider'

const SocialsEmbed = ({assets}: {assets: Asset[]}) => {
  const {register, watch} = useForm({
    defaultValues: {width: `640`, height: `360`},
  })
  const {t} = useTranslation(`socialsIntegrations`)
  const {account} = useAccount()
  const {profile} = useProfiles()
  const width = watch(`width`)
  const height = watch(`height`)

  const embedCode = useMemo(() => {
    if (!assets.length || !profile) return ``

    const {id, name, versions, type: assetType} = assets[0]
    const {userId} = profile

    const payload = {
      id: id,
      uid: userId,
      acc: account?.id,
    }

    const base64Payload = window.btoa(JSON.stringify(payload))
    const activeVersion =
      versions.length === 1
        ? versions[0]
        : versions.find(version => version.active)

    if (!activeVersion) return ``

    if (assetType === AssetType.Video) {
      const videoSrc = `${process.env.GATSBY_EMBED_SUBDOMAIN}/video/upload/${activeVersion.cloudinaryId}?p=${base64Payload}`
      return `<iframe width="${width}" height="${height}" src="${videoSrc}" frameborder="0" allowfullscreen></iframe>`
    } else {
      const imgSrc = `${process.env.GATSBY_EMBED_SUBDOMAIN}/image/upload/${activeVersion.cloudinaryId}?p=${base64Payload}`
      return `<img alt="${name}" src="${imgSrc}" />`
    }
  }, [account, assets, profile, width, height])

  return (
    <>
      <Text>{t(`embed.code`)}</Text>
      {!!assets.length && assets[0].type === AssetType.Video && (
        <div className="flex gap-4">
          <label>
            {t(`embed.width`)}
            <input
              type="number"
              {...register(`width`)}
              className="rounded border p-2"
            />
          </label>
          <label>
            {t(`embed.height`)}
            <input
              type="number"
              {...register(`height`)}
              className="rounded border p-2"
            />
          </label>
        </div>
      )}
      <TextArea
        id="embedCode"
        name="embedCode"
        label={t(`embed.code`)}
        labelHidden
        value={embedCode}
        rows={7}
        disabled
        noResize
      />
      <div className="transition-property-default active:bg-ui-50 active:text-ui-800 hover:border-ui-500 hover:text-ui-700 text-ui-600 dark:hover:border-ui-300 relative inline-flex items-center justify-center whitespace-nowrap rounded border px-4 py-2 text-left text-sm font-medium leading-5 focus:border-lime-300">
        <CopyDetail title={t(`embed.copy`)} copyText={embedCode}>
          <Text aria-label={t(`embed.copy`) || ``}>{t(`embed.copy`)}</Text>
        </CopyDetail>
      </div>
    </>
  )
}

export default withTranslationProvider(SocialsEmbed)
