import {CheckIcon} from '@heroicons/react/24/outline'
import {
  ActionGroup,
  Banner,
  Button,
  Input,
  Modal,
  Text,
  TextArea,
} from '@myadbox/gatsby-theme-nebula'
import {EVENTS, trackEventByKey} from '@myadbox/mixpanel'
import type {NestedSchema, SchemaInput} from '@myadbox/nebula-service-api'
import {useDatasets} from '@myadbox/nebula-service-api'
import {Form, Formik, FormikProps} from 'formik'
import {navigate} from 'gatsby'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {HTMLAttributes, ReactNode, useEffect} from 'react'
import {getTextValues, getValidationSchema} from './helpers'

interface Props extends HTMLAttributes<HTMLElement> {
  close?: () => void
  schema?: NestedSchema
}

const defaultInitialValues: SchemaInput = {
  title: ``,
  description: ``,
}

const SchemaForm = ({close, schema}: Props) => {
  const {t} = useTranslation()

  const {
    addSchema,
    addSchemaResult: {error: addError, data: addData, loading: addLoading},
    updateSchema,
    updateSchemaResult: {
      error: updateError,
      data: updateData,
      loading: updateLoading,
    },
  } = useDatasets()

  const initialValues = schema
    ? {title: schema.title, description: schema.description}
    : defaultInitialValues

  const handleSubmit = (values: SchemaInput) => {
    if (schema) {
      updateSchema(schema.id, values)
      trackEventByKey(EVENTS.DATABLOCK_UPDATED, {schemaId: schema.id})
    } else {
      addSchema(values)
      trackEventByKey(EVENTS.DATABLOCK_CREATED)
    }
  }

  useEffect(() => {
    if (updateData) close()
    if (addData) {
      setTimeout(() => {
        navigate(`/settings/schemas/${addData.addSchema.id}`)
      }, 500)
    }
  }, [close, addData, updateData])

  const error = addError || updateError
  const data = addData || updateData
  const loading = addLoading || updateLoading

  const validationSchema = getValidationSchema(t)

  const action = schema ? `update` : `create`
  const textValues = getTextValues(t)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({dirty}: FormikProps<SchemaInput>): ReactNode => (
        <Form>
          <Modal.Header>{textValues[action].header}</Modal.Header>
          <Modal.Body>
            <div
              className={`
                grid
                gap-4
              `}
            >
              <Input.Formik
                name="title"
                label={t`settings.schemas.titleCol`}
                id="schema-title"
                placeholder={t`settings.schemas.schemaTitlePlaceholder`}
              />

              <TextArea.Formik
                name="description"
                label={t`settings.schemas.descriptionCol`}
                id="schema-description"
                placeholder={t`settings.schemas.schemaDescriptionPlaceholder`}
              />
              {error && (
                <Banner size="sm" intent="error">
                  {error.message}
                </Banner>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ActionGroup>
              {data ? (
                <div
                  className={`
                    flex
                    items-center
                    justify-center
                    gap-1
                    text-green-600
                  `}
                >
                  <CheckIcon width={24} height={24} />
                  <Text>{textValues[action].successMsg}</Text>
                </div>
              ) : (
                <>
                  <Button variant="secondary" type="button" onClick={close}>
                    {t`settings.cancel`}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={loading || !dirty}
                    loading={loading}
                    loadingText={textValues[action].loadingText}
                  >
                    {textValues[action].actionText}
                  </Button>
                </>
              )}
            </ActionGroup>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default SchemaForm
