import {Menu, MenuList} from '@reach/menu-button'
import {ComponentProps, PropsWithChildren, ReactNode} from 'react'
import {cn} from '../../util'
import DropdownItem from './DropdownItem'
import DropdownTrigger from './DropdownTrigger'
import {DropdownTriggerSizeContext} from './helper'
import {menuClassnames} from './styles'
import {ValidSizeClassname} from './types'

import './styles/dropdown-overrides.css'

export default function Dropdown({
  trigger,
  triggerAppearance,
  children,
  ...props
}: ComponentProps<`div`> & {
  trigger: ReactNode
  triggerClassName?: ValidSizeClassname
  triggerAppearance?: ReactNode
}) {
  return (
    <Dropdown.Root {...props}>
      <Dropdown.Content trigger={trigger}>{children}</Dropdown.Content>
      {triggerAppearance}
    </Dropdown.Root>
  )
}

Dropdown.Root = function DropdownRoot({
  className,
  triggerClassName,
  ...props
}: Omit<ComponentProps<typeof Dropdown>, `trigger` | `triggerAppearance`>) {
  return (
    <div
      {...props}
      className={cn(`group relative`, className, triggerClassName)}
    >
      <DropdownTriggerSizeContext.Provider value={triggerClassName ?? ``}>
        {props.children}
      </DropdownTriggerSizeContext.Provider>
    </div>
  )
}

Dropdown.Content = function DropdownContent(
  props: PropsWithChildren<{
    trigger: ReactNode
  }>
) {
  return (
    <Menu>
      {ctx => (
        <>
          {props.trigger}
          {ctx.isExpanded ? (
            <MenuList className={menuClassnames.listBase}>
              {props.children}
            </MenuList>
          ) : null}
        </>
      )}
    </Menu>
  )
}

Dropdown.Item = DropdownItem
Dropdown.Trigger = DropdownTrigger
Dropdown.TriggerPlaceholder = DropdownTrigger.Placeholder
