export const EVENTS = {
  DOWNLOAD_TEMPLATE: `DOWNLOAD_TEMPLATE`,
  SAVE_TO_ASSETS: `SAVE_TO_ASSETS`,
  TRAY_DOWNLOAD: `TRAY_DOWNLOAD`,
  TRAY_TEXT_DOWNLOAD: `TRAY_TEXT_DOWNLOAD`,
  TRAY_SAVED_TO_ASSETS: `TRAY_SAVED_TO_ASSETS`,
  ASSET_UPLOAD_DONE: `ASSET_UPLOAD_DONE`,
  ASSET_UPLOAD_OPEN: `ASSET_UPLOAD_OPEN`,
  ASSET_SEARCH: `ASSET_SEARCH`,
  ASSET_FILTERED: `ASSET_FILTERED`,
  ASSET_NOT_FOUND: `ASSET_NOT_FOUND`,
  TEMPLATE_FIELD_CHANGE: `TEMPLATE_FIELD_CHANGE`,
  TEMPLATE_RESIZED: `TEMPLATE_RESIZED`,
  TEAM_ADDED: `TEAM_ADDED`,
  TEAM_EDITED: `TEAM_EDITED`,
  TEAM_REMOVED: `TEAM_REMOVED`,
  DATABLOCK_CREATED: `DATABLOCK_CREATED`,
  DATABLOCK_UPDATED: `DATABLOCK_UPDATED`,
  DATABLOCK_DELETED: `DATABLOCK_DELETED`,
  DATABLOCK_FIELDS_UPDATED: `DATABLOCK_FIELDS_UPDATED`,
  ASSET_INDEX_SEARCH: `ASSET_INDEX_SEARCH`,
} as const

export type TrackedEvent = (typeof EVENTS)[keyof typeof EVENTS]

type TrackedEventProperties = {name: string; properties: {section: string}}

export const trackedEvents = {
  DOWNLOAD_TEMPLATE: {
    name: `TEMPLATE_DOWNLOAD_ASSET`,
    properties: {section: `Templates`},
  },
  SAVE_TO_ASSETS: {
    name: `TEMPLATE_SAVE_ASSET`,
    properties: {section: `Assets`},
  },
  ASSET_UPLOAD_DONE: {
    name: `ASSET_UPLOAD_DONE`,
    properties: {section: `Upload`},
  },
  ASSET_UPLOAD_OPEN: {
    name: `ASSET_UPLOAD_OPEN`,
    properties: {section: `Upload`},
  },
  ASSET_SEARCH: {name: `ASSET_SEARCH`, properties: {section: `Assets`}},
  ASSET_NOT_FOUND: {name: `ASSETS_NOT_FOUND`, properties: {section: `Assets`}},
  ASSET_FILTERED: {name: `ASSET_FILTERED`, properties: {section: `Assets`}},
  TEMPLATE_FIELD_CHANGE: {
    name: `TEMPLATE_FIELD_CHANGE`,
    properties: {section: `Templates`},
  },
  TEMPLATE_RESIZED: {
    name: `TEMPLATE_RESIZED`,
    properties: {section: `Templates`},
  },
  TRAY_DOWNLOAD: {name: `TRAY_DOWNLOAD`, properties: {section: `Tray`}},
  TRAY_TEXT_DOWNLOAD: {
    name: `TRAY_TEXT_DOWNLOAD`,
    properties: {section: `Tray`},
  },
  TRAY_SAVED_TO_ASSETS: {
    name: `TRAY_SAVED_TO_ASSETS`,
    properties: {section: `Tray`},
  },
  TEAM_ADDED: {name: `TEAM_ADDED`, properties: {section: `Teams`}},
  TEAM_EDITED: {name: `TEAM_EDITED`, properties: {section: `Teams`}},
  TEAM_REMOVED: {name: `TEAM_REMOVED`, properties: {section: `Teams`}},
  DATABLOCK_CREATED: {
    name: `DATABLOCK_CREATED`,
    properties: {section: `Datablocks`},
  },
  DATABLOCK_UPDATED: {
    name: `DATABLOCK_UPDATED`,
    properties: {section: `Datablocks`},
  },
  DATABLOCK_DELETED: {
    name: `DATABLOCK_DELETED`,
    properties: {section: `Datablocks`},
  },
  DATABLOCK_FIELDS_UPDATED: {
    name: `DATABLOCK_FIELDS_UPDATED`,
    properties: {section: `Datablocks`},
  },
  ASSET_INDEX_SEARCH: {
    name: `ASSET_INDEX_SEARCH`,
    properties: {section: `Assets`},
  },
} as const satisfies Record<TrackedEvent, TrackedEventProperties>
