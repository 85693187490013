import {CheckIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {
  Button,
  Checkbox,
  Input,
  Table,
  TextArea,
} from '@myadbox/gatsby-theme-nebula'
import {NestedSchemaField} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactElement} from 'react'
import {ActionsWrapper, getHtmlFieldType} from '../EditableDataTable/helpers'

const stickyColClasses = `
  sticky
  right-0
  z-20
`

interface Props {
  rows: NestedSchemaField[]
  setRecordId: (id: string) => void
  rowIndex: number
  loading?: boolean
  remove?: () => void
}

const OVERRIDE_FORM_ITEM_WIDTH_CLASS_NAME = `[&_*]:max-w-none`
const OVERRIDE_CELL_INNER_HEIGHT_CLASS_NAME = `[&>*]:max-h-none`

const CellInnerWrapper = ({children}: {children: ReactElement}) => (
  <div
    className={`
      relative
      -mb-0.5
      -mt-1.5
      overflow-y-auto
      ${OVERRIDE_FORM_ITEM_WIDTH_CLASS_NAME}
    `}
  >
    <div className="z-10 w-full">{children}</div>
  </div>
)

export const EditRowForm = ({
  rows,
  setRecordId,
  rowIndex,
  loading,
  remove,
}: Props) => {
  const {t} = useTranslation()
  return (
    <>
      {rows.map(field => {
        const name = `rows.${rowIndex}.data.${field.name}`
        const htmlFieldType = getHtmlFieldType(field.type, field.options)
        return (
          <Table.Cell
            key={field.id}
            className={OVERRIDE_CELL_INNER_HEIGHT_CLASS_NAME}
          >
            {(htmlFieldType === `checkbox` && (
              <Checkbox.Formik
                id={field.id}
                aria-label={field.title}
                name={name}
              />
            )) ||
              (htmlFieldType === `number` && (
                <Input.Formik
                  label={field.title}
                  labelHidden
                  type="number"
                  id={field.id}
                  name={name}
                  className={`min-w-0`}
                />
              )) || (
                <CellInnerWrapper>
                  <TextArea.Formik
                    label={field.title}
                    labelHidden
                    id={field.id}
                    name={name}
                    scaleErrorMessage
                    className={`min-w-0 resize`}
                    rows={htmlFieldType === `textarea` ? 3 : 1}
                  />
                </CellInnerWrapper>
              )}
          </Table.Cell>
        )
      })}
      <Table.Cell>&nbsp;</Table.Cell>
      <Table.Cell className={stickyColClasses}>
        <ActionsWrapper>
          <Button
            size="xs"
            type="submit"
            slim
            variant="primary"
            loading={loading}
            loadingText={``}
            title={
              loading
                ? t`settings.schemas.editRow.saveBtnLoading`
                : t`settings.schemas.editRow.saveBtn`
            }
          >
            <CheckIcon width={18} />
          </Button>
          <Button
            type="button"
            variant="secondary"
            slim
            size="xs"
            disabled={loading}
            title={t`settings.schemas.editRow.cancelBtn`}
            onClick={() => {
              setRecordId(``)
              remove?.()
            }}
          >
            <XMarkIcon width={18} />
          </Button>
        </ActionsWrapper>
      </Table.Cell>
    </>
  )
}

export default EditRowForm
