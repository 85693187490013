import {DropdownTriggerVariant} from '../types'

export const buttonClassNames = {
  base: `
    items-center
    rounded
    border
    inline-flex
    font-medium
    text-left
    duration-150
    ease-in-out
    transition-default
    text-sm
    leading-5
  `,
  primary: `
    bg-lime-500
    text-lime-950
    bg-gradient-to-b
    hover:bg-lime-400
    active:from-lime-600
    disabled:bg-lime-500
    disabled:from-lime-500
    dark:border-transparent
    dark:text-lime-950
    shadow-lime-inset
    font-medium-only
  `,
  secondary: `
    active:bg-ui-50
    active:text-ui-800
    focus:border-lime-300
    hover:border-ui-500
    hover:text-ui-700
    text-ui-600
    dark:hover:border-ui-300
  `,
  disabled: `
    opacity-70
    relative
    cursor-not-allowed
  `,
  asLink: `
    py-2
    px-3
    leading-5
    no-underline
    transition-default
    hover:!bg-transparent
  `,
} satisfies Record<
  DropdownTriggerVariant | (string & Record<never, never>),
  string
>

export const iconClassnames = {
  base: `px-2 border-l`,
  primary: `border-lime-600`,
} satisfies Partial<
  Record<DropdownTriggerVariant | (string & Record<never, never>), string>
>

export const menuClassnames = {
  itemBase: `
    dark:border-ui-200
    text-ui-800
    cursor-pointer
    block
    py-2
    px-3
    duration-150
    ease-in-out
    transition-default
    hover:bg-ui-100
    hover:dark:bg-ui-100
  `,
  itemDisabled: `
    dark:border-ui-200
    dark:text-ui-400
    text-ui-600
    cursor-not-allowed
    block
    py-2
    px-3
  `,
  listBase: `
    bg-ui-50
    rounded-400
    py-1
    shadow-md
    dark:shadow-lg-dense
    dark:bg-ui-200
    text-sm
  `,
}
