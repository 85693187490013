import {PropsWithChildren, ReactNode, useId} from 'react'
import {SupportLinkMetaProp, SupportProps} from './types'
import {Avatar, Dropdown} from '@myadbox/stellar-ui'

export * from './types'

export const DEFAULT_SUPPORT_LINKS = [`/profile/user`, `/logout`] as const

export default function Support(
  props: PropsWithChildren<
    Partial<Record<`${`start` | `end`}Content`, ReactNode>>
  >
) {
  return (
    <div className={`flex items-center gap-2 self-stretch pl-3`}>
      {props.startContent}
      <Dropdown.Root triggerClassName={`w-[3rem] h-[3rem]`}>
        {props.children}
      </Dropdown.Root>
      {props.endContent}
    </div>
  )
}

Support.TriggerPlaceholder = function SupportTriggerPlaceholder(props: {
  user?: {
    avatar?: string
    fullName?: string
  }
}) {
  return (
    <Dropdown.TriggerPlaceholder
      className={`group-hover:text-ui-900 text-ui-400 p-2`}
    >
      <Avatar src={props.user?.avatar} title={props.user?.fullName} />
    </Dropdown.TriggerPlaceholder>
  )
}

Support.Content = function SupportContent<
  const L extends SupportLinkMetaProp = typeof DEFAULT_SUPPORT_LINKS
>({labels, ...props}: SupportProps<L>) {
  const links = (props.links ?? DEFAULT_SUPPORT_LINKS).map(item =>
    typeof item === `string`
      ? {
          label: labels[item as keyof typeof labels],
          to: item,
        }
      : item
  )

  const contentId = useId()

  return (
    <Dropdown.Content
      trigger={
        <Dropdown.Trigger
          unstyled
          icon={false}
          className={`
              hocus:bg-ui-100
              rounded-full
              px-2
            `}
          aria-label={labels[`toggle-user-menu`]}
          title={labels[`toggle-user-menu`]}
          id="user-menu-trigger"
        />
      }
    >
      {links.map((l, idx) =>
        props.linkNode ? (
          <Dropdown.Item
            key={`${contentId}-${idx}`}
            as={props.linkNode}
            to={l.to}
          >
            {l.label}
          </Dropdown.Item>
        ) : (
          <Dropdown.Item key={`${contentId}-${idx}`} href={l.to}>
            {l.label}
          </Dropdown.Item>
        )
      )}
    </Dropdown.Content>
  )
}
