import {useLazyQuery, useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {
  GET_AI_SUGGESTION,
  PACKAGE,
  RENDER,
  UPDATE_PACKAGE,
} from '../../mutations'
import {TEMPLATE} from '../../queries'
import {
  PackageTemplateInput,
  RenderInput,
  RenderTemplateResult,
  UpdatePackageTemplateInput,
  UseTemplatesOutput,
} from '../../types'
import {getHostedUrl} from './helpers'

export const useTemplates = ({
  cloudName,
}: {
  cloudName: string
}): UseTemplatesOutput => {
  const [lazyQuery, queryResult] = useLazyQuery(TEMPLATE)
  const query = useCallback(
    (templateUrl: string) => lazyQuery({variables: {templateUrl}}),
    [lazyQuery]
  )

  const [doRender, renderResult] = useMutation<RenderTemplateResult>(RENDER)
  const render = useCallback(
    (input: RenderInput) => {
      return doRender({variables: {input}})
    },
    [doRender]
  )

  const [doPackageTemplate, packageTemplateResult] = useMutation(PACKAGE)
  const packageTemplate = useCallback(
    (input: PackageTemplateInput) => {
      return doPackageTemplate({variables: {input}})
    },
    [doPackageTemplate]
  )

  const [doUpdatePackageTemplate, updatePackageTemplateResult] =
    useMutation(UPDATE_PACKAGE)
  const updatePackageTemplate = useCallback(
    (input: UpdatePackageTemplateInput) => {
      return doUpdatePackageTemplate({variables: {input}})
    },
    [doUpdatePackageTemplate]
  )

  const [doGetAiSuggestion, getAiSuggestionResult] =
    useMutation(GET_AI_SUGGESTION)
  const getAiSuggestion = useCallback(
    (input: string) => {
      return doGetAiSuggestion({variables: {input}})
    },
    [doGetAiSuggestion]
  )

  return {
    query,
    queryResult,
    render,
    renderResult,
    packageTemplate,
    packageTemplateResult,
    updatePackageTemplate,
    updatePackageTemplateResult,
    hostedUrl: getHostedUrl(queryResult.data?.template, cloudName),
    getAiSuggestion,
    getAiSuggestionResult,
  }
}
