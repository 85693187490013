import {Button, Modal} from '@myadbox/gatsby-theme-nebula'
import {EVENTS, trackEventByKey} from '@myadbox/mixpanel'
import {Team, User} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode} from 'react'
import TeamModalForm from './TeamModalForm'

type Props = {
  users: User[]
  team?: Team
  isNew?: boolean
}

const TeamModal = ({users, team, isNew}: Props) => {
  const {t} = useTranslation()

  return (
    <Modal
      accessibleTitle={t`settings.teams.createTeam`}
      trigger={({open}): ReactNode =>
        isNew ? (
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              open()
              trackEventByKey(EVENTS.TEAM_ADDED)
            }}
          >
            {t`settings.teams.createTeam`}
          </Button>
        ) : (
          <Button
            type="button"
            variant="link"
            slim
            onClick={() => {
              open()
              trackEventByKey(EVENTS.TEAM_EDITED, {teamId: team.id})
            }}
          >
            {t`settings.edit`}
          </Button>
        )
      }
    >
      {({close}): ReactNode => (
        <>
          <Modal.Header>
            {isNew ? t`settings.teams.createTeam` : t`settings.teams.editTeam`}
          </Modal.Header>
          <TeamModalForm
            users={users}
            team={team}
            isNew={isNew}
            close={close}
          />
        </>
      )}
    </Modal>
  )
}

export default TeamModal
