import {MenuItem, MenuItemProps, MenuLink} from '@reach/menu-button'
import {ComponentProps} from 'react'
import {cn} from '../../util'
import {noop} from '../../util/noop'
import {menuClassnames} from './styles'
import {PolymorphicProp} from './types'

export type DropdownItemProps<As = ``, Href = ``> = PolymorphicProp<
  As,
  Omit<
    MenuItemProps & {
      className?: string
    },
    `onSelect`
  > &
    (As extends ``
      ? Href extends ``
        ? {
            href?: string
            onSelect: MenuItemProps[`onSelect`]
          }
        : Href extends string
        ? {
            href: Href
            onSelect?: MenuItemProps[`onSelect`]
          }
        : never
      : {
          href?: string
          onSelect?: MenuItemProps[`onSelect`]
        })
>

export default function DropdownItem<As = ``, Href = ``>(
  props: DropdownItemProps<As, Href>
) {
  const {as, className, ...restProps} = props
  const rootCls = cn(
    props.disabled ? menuClassnames.itemDisabled : menuClassnames.itemBase,
    className
  )

  if (!as && restProps.href)
    return <MenuLink className={cn(`no-underline`, rootCls)} {...restProps} />

  if (`to` in restProps)
    return (
      <MenuLink
        className={rootCls}
        as={(as ?? `a`) as ComponentProps<typeof MenuLink>[`as`]}
        {...restProps}
      />
    )

  return (
    <MenuItem
      className={rootCls}
      as={(as ?? `div`) as ComponentProps<typeof MenuItem>[`as`]}
      onSelect={restProps.onSelect ?? noop}
      {...restProps}
    />
  )
}
